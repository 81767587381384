import { CredoAppService } from "@credolab/credoapp-sdk";
import { getExperimentEvaluationService } from "./experiments.services";

class CredoAppSdkService {
  #credoAppService;
  #initialized;

  constructor() {
    this.#initialized = this.#initialize();
  }

  async #initialize() {
    try {
      const [apiKey, url] = await Promise.all([
        getExperimentEvaluationService("credolab_api_key", "U-ADMIN", null),
        getExperimentEvaluationService("credolab_api_url", "U-ADMIN", null),
      ]);

      this.#credoAppService = new CredoAppService(url, apiKey);
    } catch (error) {
      console.error("Error initializing CredoAppService:", error);
      throw error;
    }
  }

  async #ensureInitialized() {
    if (!this.#credoAppService) {
      await this.#initialized;
    }
  }

  async startTracking() {
    try {
      await this.#ensureInitialized();
      this.#credoAppService.startTracking();
    } catch (error) {
      console.error("Error starting tracking:", error);
      throw error;
    }
  }

  async stopTrackingAndComplete(referenceNumber) {
    try {
      await this.#ensureInitialized();
      await this.#credoAppService.stopTrackingAndCompleteAsync(referenceNumber);
      console.log(`Stop tracking and complete ${referenceNumber}`);
    } catch (error) {
      console.error(
        `Error stopping tracking for reference ${referenceNumber}:`,
        error
      );
      throw error;
    }
  }
}

export default CredoAppSdkService;
