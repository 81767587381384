import { useEffect, useState } from "react";
import { MenuItem, TextField, Typography } from "@material-ui/core";
import { CurpAutocomplete, CurpAutogenerated } from "../helpers";
import { esEmailValido } from "../../utils/email";
import { ATTRIBUTION_CHANNELS } from "../../utils/constants";
import useStyles from "../NewLoan.styles";
import { CURP } from "@randyd45/curp-validation";
import { toast } from "react-toastify";

function PersonalInfo({
  formData,
  setFormData,
  handlePersonalInfo,
  email,
  setEmail,
  attributionChannel,
  setAttributionChannel,
  curpFillType,
  setCurpValidated,
}) {
  const classes = useStyles();
  const [showInfoFields, setShowInfoFields] = useState(false);
  const [fillDecided, setFillDecided] = useState(false);
  const [curpObj, setCurpObj] = useState(null);

  if (curpFillType && curpFillType !== "autocompleted") {
    if (!fillDecided) {
      setFillDecided(true);
      setShowInfoFields(true);
    }
  }

  const handleChange = (event) => {
    setAttributionChannel(event.target.value);
  };

  const validatePersonalField = (field, value) => {
    switch (field) {
      case "firstName":
        if (!curpObj.isNameValid(value)) {
          toast.info("El nombre no coincide con el CURP", { autoClose: 1500 });
          handlePersonalInfo("", "firstName");
        }
        break;
      case "lastName1":
        if (!curpObj.isFirstLastnameValid(value)) {
          toast.info("El apellido paterno no coincide con el CURP", {
            autoClose: 1500,
          });
          handlePersonalInfo("", "lastName1");
        }
        break;
      case "lastName2":
        if (!curpObj.isSecondLastnameValid(value)) {
          toast.info("El apellido materno no coincide con el CURP", {
            autoClose: 1500,
          });
          handlePersonalInfo("", "lastName2");
        }
        break;
    }
  };
  useEffect(() => {
    if (formData.personalInfo.curp.length === 18) {
      const curp = new CURP(formData.personalInfo.curp);
      setCurpObj(curp);
    }
  }, [formData.personalInfo.curp]);

  return (
    <section className={classes.sectionFlexColumn}>
      {curpFillType !== "autogenerated" && (
        <CurpAutocomplete
          formData={formData}
          setFormData={setFormData}
          handlePersonalInfo={handlePersonalInfo}
          setShowInfoFields={setShowInfoFields}
          curpFillType={curpFillType}
        />
      )}
      {showInfoFields && (
        <section className={classes.sectionFlexColumn}>
          <TextField
            id="name-input"
            label="Nombre(s)"
            value={formData?.personalInfo?.firstName}
            onChange={(e) => handlePersonalInfo(e.target.value, "firstName")}
            onBlur={() =>
              validatePersonalField(
                "firstName",
                formData?.personalInfo?.firstName
              )
            }
            inputProps={{
              className: classes.textFieldUppercase,
            }}
            variant="outlined"
            fullWidth
            error={formData?.personalInfo?.firstName.length < 3}
          />
          <section style={{ display: "flex" }}>
            <TextField
              id="last-name-1-input"
              label="Apellido paterno *"
              value={formData?.personalInfo?.lastName1}
              onChange={(e) => handlePersonalInfo(e.target.value, "lastName1")}
              onBlur={() =>
                validatePersonalField(
                  "lastName1",
                  formData?.personalInfo?.lastName1
                )
              }
              inputProps={{
                className: classes.textFieldUppercase,
              }}
              variant="outlined"
              className={`${classes.halfWidthLeft}`}
              error={formData?.personalInfo?.lastName1.length < 3}
            />
            <TextField
              id="last-name-2-input"
              label="Apellido materno *"
              value={formData?.personalInfo?.lastName2}
              onChange={(e) => handlePersonalInfo(e.target.value, "lastName2")}
              onBlur={() =>
                validatePersonalField(
                  "lastName2",
                  formData?.personalInfo?.lastName2
                )
              }
              inputProps={{
                className: classes.textFieldUppercase,
              }}
              variant="outlined"
              className={classes.halfWidthRight}
              error={formData?.personalInfo?.lastName2.length < 3}
            />
          </section>
          <Typography variant="caption">Fecha de nacimiento *</Typography>
          <section style={{ display: "flex" }}>
            <TextField
              id="day-input"
              label="Día (DD)"
              placeholder="00"
              value={formData?.personalInfo?.day}
              onChange={(e) => handlePersonalInfo(e.target.value, "day")}
              inputProps={{
                className: classes.textFieldUppercase,
                maxLength: 2,
                type: "number",
              }}
              inputMode="numeric"
              variant="outlined"
              className={classes.threeColumnSplit}
              error={formData?.personalInfo?.day.length < 1}
            />
            <TextField
              id="month-input"
              label="Mes (MM)"
              placeholder="00"
              value={formData?.personalInfo?.month}
              onChange={(e) => handlePersonalInfo(e.target.value, "month")}
              inputProps={{
                className: classes.textFieldUppercase,
                maxLength: 2,
                type: "number",
              }}
              inputMode="numeric"
              variant="outlined"
              className={`${classes.threeColumnSplit} ${classes.halfWidthRight}`}
              error={formData?.personalInfo?.month.length < 1}
            />
            <TextField
              id="year-input"
              label="Año (AAAA)"
              placeholder="0000"
              value={formData?.personalInfo?.year}
              onChange={(e) => handlePersonalInfo(e.target.value, "year")}
              inputProps={{
                className: classes.textFieldUppercase,
                maxLength: 4,
                type: "number",
              }}
              variant="outlined"
              className={`${classes.threeColumnSplit} ${classes.halfWidthRight}`}
              error={formData?.personalInfo?.year.length < 4}
            />
          </section>
          <section style={{ display: "flex" }}>
            <TextField
              id="rfc-input"
              label="RFC *"
              disabled
              value={formData?.personalInfo?.rfc}
              helperText="Verifica que sea correcto"
              onChange={(e) => handlePersonalInfo(e.target.value, "rfc")}
              inputProps={{
                className: classes.textFieldUppercase,
                maxLength: 10,
              }}
              variant="outlined"
              className={`${classes.fullWidth} ${classes.rootRequired}`}
            />
            <TextField
              id="homoclave-input"
              label="Homoclave"
              value={formData?.personalInfo?.homoclave}
              helperText="Opcional"
              onChange={(e) => handlePersonalInfo(e.target.value, "homoclave")}
              inputProps={{
                className: classes.textFieldUppercase,
                maxLength: 3,
              }}
              variant="outlined"
              className={`${classes.rootRequired} ${classes.halfWidthRight}`}
            />
          </section>
          <TextField
            id="email-input"
            label="Email *"
            value={email}
            error={!esEmailValido(email)}
            helperText={!esEmailValido(email) ? "Ingresa un email válido" : ""}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            className={classes.rootRequired}
          />
          <TextField
            id="attribution-channel-input"
            select
            label="¿Cómo conociste Beloz?"
            error={!attributionChannel}
            value={attributionChannel}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            className={classes.rootRequired}
          >
            {ATTRIBUTION_CHANNELS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </section>
      )}
      {curpFillType === "autogenerated" && (
        <>
          <Typography variant="subtitle1" className={classes.subHeader}>
            Válida tu CURP
          </Typography>
          <CurpAutogenerated
            formData={formData}
            handlePersonalInfo={handlePersonalInfo}
            showInfoFields={showInfoFields}
            setShowInfoFields={setShowInfoFields}
            setCurpValidated={setCurpValidated}
          />
        </>
      )}
    </section>
  );
}

export default PersonalInfo;
